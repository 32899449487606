<template>
    <div class="online-event-edit-view">
        <online-event-form :event-id="eventId" />
    </div>
</template>

<script>
import OnlineEventForm from '@/modules/online-event/components/OnlineEventForm';
export default {
    name: 'EventEditView',
    components: { OnlineEventForm },
    computed: {
        eventId() {
            return this.$route.params.eventId;
        }
    }
};
</script>

<style lang="scss">
.online-event-edit-view {

}
</style>
