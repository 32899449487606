<template>
    <div class="event-edit-view">
        <event-form :event-id="eventId" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import EventForm from '@/modules/event/components/EventForm';
import { ROUTES } from '@/enums';

export default {
    name: 'EventEditView',

    components: {
        EventForm
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        ...mapState('event', {
            event: 'resource'
        }),

        title() {
            return this.$prop('event.attributes.title');
        },

        $breadcrumbs() {
            const bc = [
                {
                    title: 'События',
                    route: ROUTES.EVENT.LIST
                }
            ];
            if (this.title) {
                bc.push({
                    title: this.title,
                    route: ROUTES.EVENT.CARD,
                    params: {
                        eventId: this.eventId
                    }
                });
            }
            bc.push({
                title: 'Редактирование',
                route: ROUTES.EVENT.EDIT,
                params: {
                    eventId: this.eventId
                }
            });
            return bc;
        },

        eventId() {
            return this.$route.params.eventId;
        }
    }
};
</script>
